import React from 'react'
import "./FAQ.css"
import { Link } from 'react-router-dom';
const FAQs = () => {

    const company_email = "info@billingwheels.com";

    const handleEmailButtonClick = () => {
        if (window.cordova) {
            document.getElementById('emailButton').addEventListener('click', function () {
                var email = company_email;
                var emailLink = 'mailto:' + email;
                window.open(emailLink, '_system', 'location=yes');
            });
        } else {

        }
        window.location.href = `mailto:${company_email}`;
    };
    return (
        <>
            <div
                className='mb-5'
                style={{
                    backgroundColor: "#10233c",
                    color: "white",
                    height: "40px",
                    textAlign: "center"
                }}>
                <h4 className="pt-1">Frequently Asked Questions ?</h4>
            </div>
            <div className='email-container'>
                Thank you for using our services ! In case of any query please contact us. {" "} +919522460436
                {/* <Link to="/privacypolicy">Privacy Policy</Link> */}
            </div>
            <div onClick={handleEmailButtonClick} id='emailButton' className='email-container-btn'><i class="bi bi-envelope-open-fill"></i></div>

            <div className="accordion mt-5" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                            What is Billing Wheel App ?
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                        <div class="accordion-body">
                            Billing wheels  is an Application that can be used by truck owners Truck owners
                            Users can easily start trips through our platform once businesses, drivers, and trucks are set up. They can coordinate and improve overall trip management by initiating and managing trips, tracking route progress, and managing trip-related information.                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                            How to use Billing Wheels ?                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                        <div class="accordion-body">
                            To use this app, the initial step is to register your firm. Once registered, you can select your firm from the displayed options at the top. After selecting your firm, you have the ability to make changes to your firm information.

                            Following firm selection, you can proceed to add drivers and trucks. Additionally, you have the option to add consignors, consignees, and products. Once all necessary details are set up, you are ready to start a Truck Trip. The app simplifies the process, guiding you through each step for a seamless experience
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                            How to Manage Truck Trip ?                       </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                        <div class="accordion-body">
                            To begin a trip, you need to complete two crucial steps: Trip Details and Invoice Details.

                            For Trip Details, you'll need to specify essential information such as the truck, consigner, consignee, and the product being transported. Ensure you provide all the necessary details for a smooth and accurate trip record.

                            Moving on to the Invoice Details, you'll be required to mention the quantity being transported and the freight charges. This step finalizes the financial aspect of the trip.

                            After completing both of these steps, your trip will commence.
                            <br />
                            To handle expenses, you need to select the type of expense, input the expense amount, specify the truck, indicate the expense category, and provide other relevant information.
                        </div>
                    </div>
                </div>
                <div class="accordion-item mb-3">
                    <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                            How to Manage Invoice ?
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                        <div class="accordion-body">
                            Once in the update section, review the details of your invoice carefully, ensuring accuracy.
                            Pay any outstanding or remaining amounts using the provided payment options. This step will update your invoice accordingly.

                            If there are specific trips you need to settle with additional remarks or details, utilize the "Settle Trip with Remark" feature.
                        </div>
                    </div>
                </div>


            </div>
            <Link to="/privacypolicy" className='ms-3'>Privacy Policy</Link>
        </>
    )
}

export default FAQs